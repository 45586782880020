





















import {Vue, Component} from "vue-property-decorator";
import DepositRecordType from "./DepositRecord";
import HeadTop from "@/components/ShareComponent/HeadTop.vue";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import DepositRecordItem from "@/views/Money/DepositRecord/components/Item/index.vue";
import ZoomPage from "@/impView/PageSafety";
import {getDepositWater} from "@/views/Money/DepositRecord/Server";

@Component({name: "DepositRecord",components:{ HeadTop,PullDownUpList,ListBottom,DepositRecordItem }})
export default class DepositRecord extends ZoomPage implements DepositRecordType{
    PullDown = false
    StopUp = false
    UpDownBool = false
    upData={
        pageNo:1,
        pageSize:15,
        userId:""
    }
    List = []
    id = ""

    mounted(){
        this.loadingShow = false
        this.handleUpData()
    }

    activated(){
        // let { id = "" } = this.$route.query
        // if ( id != this.id ) {
        //     this.id = id as string;
        //     this.upData.pageNo = 1;
        //     this.StopUp = false
        //     this.handleUpData()
        // }
    }

    handlePullDown(): void {
        this.upData.pageNo = 1
        this.PullDown = true
        this.StopUp = false
        this.handleUpData(true)
    }

    handleUpDown(): void {
        if ( this.StopUp )return;
        this.upData.pageNo += 1
        this.UpDownBool = true
        this.handleUpData()
    }

    handleUpData(bool=false): void {
        let { List } = this
        getDepositWater(this.upData).then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < this.upData.pageSize )this.StopUp = true;
            if ( bool ) this.List = res;
            else this.List = List.concat(res);
        })
    }
}
