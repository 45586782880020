

import Axios from "@/Api/Config/basics";
import {
    AccountWaterType, AddAccountType, ApplyMoneyType,
    BodyType,
    DelAccountType,
    DepositAuditType,
    DepositListType, DepositWaterType,
    HeadType,
    UpdateAccountType
} from "@/Api/Money/indexType";

// 我的余额 - 账户详情
export function AccountSuc(headers : HeadType ) {
    return (
        Axios({
            url:"/app/account/audit/iAccount",
            method:"POST",
            headers,
            data:{}
        })
    )
}

// 我的余额 - 账户流水
export function AccountWater(headers : HeadType,body:AccountWaterType ) {
    return (
        Axios({
            url:"/app/account/audit/balanceDetail",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查询 账单流水
export function BillRunning(headers : HeadType,body:DelAccountType ) {
    return (
        Axios({
            url:"/app/queryFlowById",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 申请提现
export function ApplyMoney(headers : HeadType,body:ApplyMoneyType ) {
    return (
        Axios({
            url:"/app/account/audit/createAudit",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 提现流水
export function DepositWater(headers : HeadType,body:DepositWaterType ) {
    return (
        Axios({
            url:"/app/account/audit/withdrawalRecord",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 管理员审核列表
export function DepositList(headers : HeadType,body:DepositListType) {
    return (
        Axios({
            url:"/app/account/audit/adminAuditList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 管理员审核
export function DepositAudit(headers : HeadType,body:DepositAuditType) {
    return (
        Axios({
            url:"/app/account/audit/createAudit",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 账户列表
export function AccountList(headers : HeadType,body:BodyType) {
    return (
        Axios({
            url:"/app/account/withdrawal/queryUserAccount",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 添加账户
export function AddAccount(headers : HeadType,body:AddAccountType) {
    return (
        Axios({
            url:"/app/account/withdrawal/createUserAccount",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改账户
export function UpdateAccount(headers : HeadType,body:UpdateAccountType) {
    return (
        Axios({
            url:"/app/account/withdrawal/updateAccount",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除账户
export function DelAccount(headers : HeadType,body:DelAccountType) {
    return (
        Axios({
            url:"/app/account/withdrawal/deleteAccount",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 获取默认账户
export function DefaultAccount(headers : HeadType) {
    return (
        Axios({
            url:"/app/account/withdrawal/queryDefaultAccount",
            method:"POST",
            headers,
            data:{}
        })
    )
}
