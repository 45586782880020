




















import {Vue, Component, Prop} from "vue-property-decorator";
import DepositRecordItemType from "./indexType";
import { OrderStatus } from '../../Model';
import moment from "moment";

@Component({name: "DepositRecordItem"})
export default class DepositRecordItem extends Vue implements DepositRecordItemType{

    @Prop(Object)
    data!:any

    get getData(){
        return this.data
    }

    @Prop(Number)
    index!:number

    get getIndex(){
        return this.index
    }

    get getStatus(){
        if ( this.getData ){
            try{
                return OrderStatus[this.getData.auditStatus+'']
            }catch (e) {
                return OrderStatus["0"]
            }
        }else{
            return OrderStatus['6']
        }
    }

    get getTime(){
        if ( this.getData && this.getData.updateTime ){
            return moment(this.getData.updateTime).format("YYYY-MM-DD HH:mm")
        }else{
            return this.getData && this.getData.updateTime
        }
    }
}
